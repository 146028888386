import React, { useState, useEffect } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { collection, query, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import './wapParks.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const WapParks = () => {
  const [parks, setParks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [currentPark, setCurrentPark] = useState(null);
  const [newApiId, setNewApiId] = useState({ key: '', value: '' });

  useEffect(() => {
    const fetchParks = async () => {
      setLoading(true);
      const q = query(collection(db, "whatsAppGroups"));
      const querySnapshot = await getDocs(q);
      const parksData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setParks(parksData);
      setLoading(false);
    };

    fetchParks();
  }, []);

  const openSlider = (parkId) => {
    const parkData = parks.find(park => park.id === parkId);
    setCurrentPark(parkData);
    setIsPaneOpen(true);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (index !== undefined) {
      const updatedGateTimes = currentPark.gateTimes;
      updatedGateTimes[index][name] = value;
      setCurrentPark({ ...currentPark, gateTimes: updatedGateTimes });
    } else {
      setCurrentPark({ ...currentPark, [name]: value });
    }
  };

  const handleAddApiId = () => {
    setCurrentPark({ 
      ...currentPark, 
      apiIDs: { 
        ...currentPark.apiIDs, 
        [newApiId.key]: newApiId.value 
      } 
    });
    setNewApiId({ key: '', value: '' });
  };

  const handleSave = async () => {
    const parkRef = doc(db, "whatsAppGroups", currentPark.id);
    await updateDoc(parkRef, currentPark);
    const updatedParks = parks.map(park =>
      park.id === currentPark.id ? currentPark : park
    );
    setParks(updatedParks);
    setIsPaneOpen(false);
  };

  return (
    <div className='users__container'>
      <h1 className='users__heading'>Parks</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <ul className='users__list'>
          {parks.map(park => (
            <li key={park.id} className='users__list-item'>
              <span>{park.parkName}</span>
              <button 
                className='users__list-item-button' 
                onClick={() => openSlider(park.id)}
              >
                View Details
              </button>
            </li>
          ))}
        </ul>
      )}
      <SlidingPane
        isOpen={isPaneOpen}
        title='Park Details'
        className="park-details-pane"
        onRequestClose={() => setIsPaneOpen(false)}
      >
        {currentPark && (
          <Box
            component="form"
            className="park-details-form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="park-details-section">
              <TextField
                variant="outlined"
                id="parkName"
                label="Park Name"
                name="parkName"
                value={currentPark.parkName}
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                id="linkToJoin"
                label="Link To Join"
                name="linkToJoin"
                value={currentPark.linkToJoin}
                onChange={handleInputChange}
              />
            </div>
            <div className="park-details-section">
              <h3>API IDs</h3>
              {currentPark.apiIDs && Object.entries(currentPark.apiIDs).map(([key, value]) => (
                <TextField
                  key={key}
                  variant="outlined"
                  id={key}
                  label={key}
                  name={key}
                  value={value}
                  onChange={handleInputChange}
                />
              ))}
              <div className="new-apiid-inputs">
                <TextField
                  variant="outlined"
                  id="newApiKeyId"
                  label="New Group Name"
                  value={newApiId.key}
                  onChange={(e) => setNewApiId({ ...newApiId, key: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  id="newApiValueId"
                  label="New Group API Code"
                  value={newApiId.value}
                  onChange={(e) => setNewApiId({ ...newApiId, value: e.target.value })}
                />
                <Button variant="contained" color="primary" onClick={handleAddApiId}>
                  Add API ID
                </Button>
              </div>
            </div>
            {currentPark.gateTimes && currentPark.gateTimes.length > 0 && (
              <div className="park-details-section">
                {currentPark.gateTimes.map((time, index) => (
                  <div key={index} className="gate-time-section">
                    <h3>Gate Time {index + 1}</h3>
                    {time.months && time.months.length > 0 && time.months.map((month, monthIndex) => (
                      <TextField
                        key={monthIndex}
                        variant="outlined"
                        id={`months${index}-${monthIndex}`}
                        label="Month"
                        name="month"
                        value={month}
                        onChange={(e) => handleInputChange(e, index)}
                        disabled
                      />
                    ))}
                    <TextField
                      variant="outlined"
                      id={`campOpen${index}`}
                      label="Camp Open"
                      name="campOpen"
                      value={time.campOpen}
                      onChange={(e) => handleInputChange(e, index)}
                      disabled
                    />
                    <TextField
                      variant="outlined"
                      id={`gatesOpen${index}`}
                      label="Gates Open"
                      name="gatesOpen"
                      value={time.gatesOpen}
                      onChange={(e) => handleInputChange(e, index)}
                      disabled
                    />
                    <TextField
                      variant="outlined"
                      id={`campClose${index}`}
                      label="Camp Close"
                      name="campClose"
                      value={time.campClose}
                      onChange={(e) => handleInputChange(e, index)}
                      disabled
                    />
                  </div>
                ))}
              </div>
            )}
            <div className="park-details-section">
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </div>
          </Box>
        )}
      </SlidingPane>
    </div>
  );
};

export default WapParks;
