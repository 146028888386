import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../stores/authStore';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '../../firebase';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import './Settings.css';

const Settings = () => {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Cancel subscription
  const [isCanceling, setIsCanceling] = useState(false);
  const [targetCancelUserId, setTargetCancelUserId] = useState('');

  // Refund payment
  const [isRefunding, setIsRefunding] = useState(false);
  const [targetRefundUserId, setTargetRefundUserId] = useState('');

  // Change user's email
  const [currentEmail, setCurrentEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [isChangingEmail, setIsChangingEmail] = useState(false);

  // Delete user
  const [deleteUserId, setDeleteUserId] = useState('');
  const [isDeletingUser, setIsDeletingUser] = useState(false);

  // Merge accounts
  const [oldMergeEmail, setOldMergeEmail] = useState('');
  const [newMergeEmail, setNewMergeEmail] = useState('');
  const [isMerging, setIsMerging] = useState(false);

  // Set Custom Claims
  const [claimsUid, setClaimsUid] = useState('');
  const [adminClaim, setAdminClaim] = useState(false);
  const [premiumClaim, setPremiumClaim] = useState(false);
  const [subscribedClaim, setSubscribedClaim] = useState(false);
  const [isSettingClaims, setIsSettingClaims] = useState(false);

  // Get Custom Claims
  const [isGettingClaims, setIsGettingClaims] = useState(false);

  const user = useAuthStore((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      const q = query(collection(db, 'users'), where('bankAccountNumber', '>', ''));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        list.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setAccounts(list);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  // Initialize workbook and worksheet for export
  let workbook = new Excel.Workbook();
  let worksheet = workbook.addWorksheet('your export sheet name');
  worksheet.columns = [
    { header: 'First Name', key: 'firstName' },
    { header: 'Last Name', key: 'lastName' },
    { header: 'Email', key: 'email' },
    { header: 'Bank Name', key: 'bankAccountName' },
    { header: 'Account Number', key: 'bankAccountNumber' },
    { header: 'Branch Code', key: 'bankBranchCode' },
    { header: 'Paypal', key: 'paypalEmail' },
    { header: 'User Id', key: 'uid' },
  ];
  worksheet.getRow(1).font = { bold: true };

  const getData = async () => {
    let list = [];
    const q = query(collection(db, 'users'), where('bankAccountNumber', '>', ''));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      list.push({ ...doc.data(), id: doc.id });
    });
    const q2 = query(collection(db, 'users'), where('paypalEmail', '>', ''));
    const querySnapshot2 = await getDocs(q2);
    querySnapshot2.forEach((doc) => {
      list.push({ ...doc.data(), id: doc.id });
    });
    const uniqueIds = [...new Set(list.map((item) => item.id))];
    const uniqueList = uniqueIds.map((id) => list.find((item) => item.id === id));
    uniqueList.forEach((item) => {
      worksheet.addRow({ ...item });
    });
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, 'fileName' + fileExtension);
  };

  // Initialize Functions
  const functions = getFunctions();

  // Cancel Subscription
  const handleCancelSubscription = async () => {
    if (!targetCancelUserId) {
      alert('Please enter a user ID.');
      return;
    }
    const confirmed = window.confirm(
      `Are you sure you want to cancel the subscription for User ID "${targetCancelUserId}"?`
    );
    if (!confirmed) return;
    const cancelPayfastSubscription = httpsCallable(functions, 'payfast-cancelUserSubscription');
    setIsCanceling(true);
    try {
      const result = await cancelPayfastSubscription({ userId: targetCancelUserId });
      console.log('Subscription canceled:', result.data);
      alert(result.data.message || 'Subscription canceled successfully.');
    } catch (err) {
      console.error('Error canceling subscription:', err);
      alert(err.message || 'Error canceling subscription.');
    } finally {
      setIsCanceling(false);
    }
  };

  // Refund Payment
  const handleRefundPayment = async () => {
    if (!targetRefundUserId) {
      alert('Please enter a user ID.');
      return;
    }
    const confirmed = window.confirm(
      `Are you sure you want to refund the last payment for User ID "${targetRefundUserId}"?`
    );
    if (!confirmed) return;
    const refundPayment = httpsCallable(functions, 'payfast-refundTransaction');
    setIsRefunding(true);
    try {
      const result = await refundPayment({ userId: targetRefundUserId });
      console.log('Last transaction refunded:', result.data);
      alert(result.data.message || 'Last transaction refunded successfully.');
    } catch (err) {
      console.error('Error refunding transaction:', err);
      alert(err.message || 'Error refunding transaction.');
    } finally {
      setIsRefunding(false);
    }
  };

  // Change User's Email
  const handleEmailChange = async () => {
    if (!currentEmail || !newEmail) {
      alert('Please enter both the current email and the new email.');
      return;
    }
    const confirmed = window.confirm(
      `Are you sure you want to change email from "${currentEmail}" to "${newEmail}"?`
    );
    if (!confirmed) return;
    setIsChangingEmail(true);
    try {
      const updateUserEmail = httpsCallable(functions, 'userManagement-changeEmails');
      const result = await updateUserEmail({ oldEmail: currentEmail, newEmail: newEmail });
      console.log('Email updated:', result.data);
      alert(result.data.message || 'Email updated successfully.');
    } catch (err) {
      console.error('Error updating email:', err);
      alert(err.message || 'Error updating email.');
    } finally {
      setIsChangingEmail(false);
    }
  };

  // Delete User
  const handleDeleteUser = async () => {
    if (!deleteUserId) {
      alert('Please enter a user ID to delete.');
      return;
    }
    const confirmed = window.confirm(
      `Are you sure you want to delete the user with ID "${deleteUserId}"? This action cannot be undone!`
    );
    if (!confirmed) return;
    setIsDeletingUser(true);
    try {
      const cancelPayfastSubscription = httpsCallable(functions, 'payfast-cancelUserSubscription');
      try {
        const cancelResult = await cancelPayfastSubscription({ userId: deleteUserId });
        console.log('Subscription canceled:', cancelResult.data);
      } catch (error) {
        console.warn('Error canceling subscription:', error.message);
      }
      const deleteUser = httpsCallable(functions, 'userManagement-deleteUser');
      const deleteResult = await deleteUser({ userId: deleteUserId });
      console.log('Delete user result:', deleteResult.data);
      alert(deleteResult.data.message || 'User deleted successfully.');
    } catch (err) {
      console.error('Error deleting user:', err);
      alert(err.message || 'Error deleting user.');
    } finally {
      setIsDeletingUser(false);
    }
  };

  // Merge Accounts
  const handleMergeAccounts = async () => {
    if (!oldMergeEmail || !newMergeEmail) {
      alert('Please enter both the old user email and the new user email.');
      return;
    }
    const confirmed = window.confirm(
      `Are you sure you want to merge the account for "${oldMergeEmail}" into "${newMergeEmail}"?`
    );
    if (!confirmed) return;
    setIsMerging(true);
    try {
      const mergeAccountsFn = httpsCallable(functions, 'userManagement-mergeAccounts');
      const result = await mergeAccountsFn({ oldEmail: oldMergeEmail, newEmail: newMergeEmail });
      console.log('Merge accounts result:', result.data);
      alert(result.data.message || 'Accounts merged successfully.');
    } catch (err) {
      console.error('Error merging accounts:', err);
      alert(err.message || 'Error merging accounts.');
    } finally {
      setIsMerging(false);
    }
  };

  // Set Custom Claims
  const handleSetCustomClaims = async () => {
    if (!claimsUid) {
      alert('Please enter a user ID for claims update.');
      return;
    }
    console.log('Setting custom claims for user:', claimsUid);
    console.log('Admin:', adminClaim);
    console.log('Premium Subscriber:', premiumClaim);
    console.log('Subscribed Yearly:', subscribedClaim);
    setIsSettingClaims(true);
    try {
      const setClaims = httpsCallable(functions, 'userManagement-setCustomClaims');
      const result = await setClaims({
        uid: claimsUid,
        admin: adminClaim,
        isPremiumSubscriber: premiumClaim,
        subscribedYearly: subscribedClaim,
      });
      console.log('Custom claims updated:', result.data);
      alert(result.data.message || 'Custom claims updated successfully.');
    } catch (err) {
      console.error('Error updating claims:', err);
      alert(err.message || 'Error updating claims.');
    } finally {
      setIsSettingClaims(false);
    }
  };

  // Get Current Custom Claims & Email Verification
  const handleGetCustomClaims = async () => {
    if (!claimsUid) {
      alert('Please enter a user ID to fetch claims.');
      return;
    }
    setIsGettingClaims(true);
    try {
      const getClaims = httpsCallable(functions, 'userManagement-getCustomClaims');
      const result = await getClaims({ uid: claimsUid });
      console.log('Fetched claims:', result.data);
      alert(
        `Custom Claims: ${JSON.stringify(result.data.customClaims)}\n` +
        `Email Verified: ${result.data.emailVerified ? "Yes" : "No"}`
      );
    } catch (err) {
      console.error('Error fetching custom claims:', err);
      alert(err.message || 'Error fetching custom claims.');
    } finally {
      setIsGettingClaims(false);
    }
  };

  return (
    <div className="account__container">
      <h1 className="account__heading" style={{ fontWeight: 'bold' }}>Settings</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <p>User email: {user && user.email}</p>
          <br />
          <p>Export bank accounts to excel</p>
          <Button variant="contained" onClick={getData} style={{ marginBottom: '1rem' }}>
            Export
          </Button>
          <div className="section-grid">
            {/* Cancel Subscription Section */}
            <div className="section-item">
              <h3 style={{ fontWeight: 'bold' }}>Cancel User Subscription</h3>
              <input
                id="cancelSubscriptionUserId"
                type="text"
                value={targetCancelUserId}
                onChange={(e) => setTargetCancelUserId(e.target.value)}
                placeholder="Enter userId..."
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancelSubscription}
                disabled={isCanceling}
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '10px' }}
              >
                {isCanceling ? (
                  <>
                    <CircularProgress size={20} />
                    Canceling...
                  </>
                ) : (
                  'Cancel Subscription'
                )}
              </Button>
            </div>
            {/* Refund Payment Section */}
            <div className="section-item">
              <h3 style={{ fontWeight: 'bold' }}>Refund last subscription payment</h3>
              <input
                id="refundPaymentUserId"
                type="text"
                value={targetRefundUserId}
                onChange={(e) => setTargetRefundUserId(e.target.value)}
                placeholder="Enter userId..."
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleRefundPayment}
                disabled={isRefunding}
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '10px' }}
              >
                {isRefunding ? (
                  <>
                    <CircularProgress size={20} />
                    Refunding...
                  </>
                ) : (
                  'Refund'
                )}
              </Button>
            </div>
            {/* Change a User's Email Section */}
            <div className="section-item">
              <h3 style={{ fontWeight: 'bold' }}>Change a user's email</h3>
              <label htmlFor="currentEmail">Current email:</label>
              <input
                id="currentEmail"
                type="text"
                value={currentEmail}
                onChange={(e) => setCurrentEmail(e.target.value)}
                placeholder="Current email..."
              />
              <label htmlFor="newEmail">New email:</label>
              <input
                id="newEmail"
                type="text"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="New email..."
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleEmailChange}
                disabled={isChangingEmail}
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '10px' }}
              >
                {isChangingEmail ? (
                  <>
                    <CircularProgress size={20} />
                    Updating...
                  </>
                ) : (
                  'Change Email'
                )}
              </Button>
            </div>
            {/* Delete a User Section */}
            <div className="section-item">
              <h3 style={{ fontWeight: 'bold' }}>Delete a User</h3>
              <label htmlFor="deleteUserId">User ID:</label>
              <input
                id="deleteUserId"
                type="text"
                value={deleteUserId}
                onChange={(e) => setDeleteUserId(e.target.value)}
                placeholder="Enter userId..."
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDeleteUser}
                disabled={isDeletingUser}
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '10px' }}
              >
                {isDeletingUser ? (
                  <>
                    <CircularProgress size={20} />
                    Deleting...
                  </>
                ) : (
                  'Delete User'
                )}
              </Button>
            </div>
            {/* Merge Accounts Section */}
            <div className="section-item">
              <h3 style={{ fontWeight: 'bold' }}>Merge Accounts</h3>
              <label htmlFor="oldMergeEmail">Old Account Email:</label>
              <input
                id="oldMergeEmail"
                type="text"
                value={oldMergeEmail}
                onChange={(e) => setOldMergeEmail(e.target.value)}
                placeholder="Old user email..."
              />
              <label htmlFor="newMergeEmail">New Account Email:</label>
              <input
                id="newMergeEmail"
                type="text"
                value={newMergeEmail}
                onChange={(e) => setNewMergeEmail(e.target.value)}
                placeholder="New user email..."
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleMergeAccounts}
                disabled={isMerging}
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '10px' }}
              >
                {isMerging ? (
                  <>
                    <CircularProgress size={20} />
                    Merging...
                  </>
                ) : (
                  'Merge'
                )}
              </Button>
            </div>
            {/* Set Custom Claims Section */}
            <div className="section-item">
              <h3 style={{ fontWeight: 'bold' }}>Set Custom Claims</h3>
              <label htmlFor="claimsUid">User ID:</label>
              <input
                id="claimsUid"
                type="text"
                value={claimsUid}
                onChange={(e) => setClaimsUid(e.target.value)}
                placeholder="Enter user ID..."
              />
              <div style={{ marginTop: '10px' }}>
                <label>
                  <input
                    type="checkbox"
                    checked={adminClaim}
                    onChange={(e) => setAdminClaim(e.target.checked)}
                  />{' '}
                  Admin
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={premiumClaim}
                    onChange={(e) => setPremiumClaim(e.target.checked)}
                  />{' '}
                  Premium Subscriber
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={subscribedClaim}
                    onChange={(e) => setSubscribedClaim(e.target.checked)}
                  />{' '}
                  Subscribed Yearly
                </label>
              </div>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSetCustomClaims}
                disabled={isSettingClaims}
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '10px' }}
              >
                {isSettingClaims ? (
                  <>
                    <CircularProgress size={20} />
                    Updating...
                  </>
                ) : (
                  'Update Claims'
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGetCustomClaims}
                disabled={isGettingClaims}
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '10px' }}
              >
                {isGettingClaims ? (
                  <>
                    <CircularProgress size={20} />
                    Fetching Claims...
                  </>
                ) : (
                  'Show Current Claims'
                )}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Settings;
