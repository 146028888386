import React, { useEffect, useState } from 'react';
import './PostTests.css';

// Components
import Table from '../../../../components/Table/Table';
import Search from '../../../../components/Users/Search';

// Firebase
import {
  collection,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where
} from "firebase/firestore";
import { db } from '../../../../firebase';

// Get test
import { fetchPostTests } from '../../../../utils/firebase/fetchPostTests';

const PostTests = () => {
  const [testsDB, setTestsDB] = useState([]);
  const [loading, setLoading] = useState(true);

  // Firebase pagination
  const [pageSize, setPageSize] = useState(5);
  const [lastVisible, setLastVisible] = useState(null);
  const [firstVisible, setFirstVisible] = useState(null);

  // Keep a reference to the currently active test
  const [activeTest, setActiveTest] = useState(null);

  const columns = [
    { label: "Edit", accessor: "edit", sortable: true, date: false },
    { label: "Active", accessor: "active", sortable: false, date: false, link: false },
    { label: "Title", accessor: "testTitle", sortable: true, date: false },
    { label: "Post Time", accessor: "postTime", sortable: true, date: true, sortbyOrder: "desc" },
    { label: "Total Loads", accessor: "totalLoads", sortable: true, date: false },
    { label: "Total Views", accessor: "totalViews", sortable: false, date: false, link: false },
    { label: "CTR", accessor: "ctr", sortable: true, date: false },
    { label: "Likes", accessor: "likes", sortable: false, date: false },
  ];

  // Helper function to format numbers and compute CTR
  const formatTestItem = (item) => {

    const totalLoads = item.totalLoads.length;
    const totalViews = item.totalViews.length;
    // Calculate CTR as percentage with two decimal places
    const ctr = totalLoads && totalViews ? ((totalViews / totalLoads) * 100).toFixed(2) + "%" : "0%";
    return { ...item, ctr };
  };

  // 1. A helper function to fetch the single active test if it exists.
  const fetchActiveTest = async () => {
    try {
      const q = query(
        collection(db, "postTests"),
        where("active", "==", true),
        limit(1)
      );
      const snap = await getDocs(q);
      if (!snap.empty) {
        // We only ever expect one doc
        const doc = snap.docs[0];
        return { id: doc.id, ...doc.data() };
      }
      return null;
    } catch (error) {
      console.error("Error fetching active test:", error);
      return null;
    }
  };

  // 2. For the initial page load (and whenever pageSize changes):
  const handleGetTests = async () => {
    setLoading(true);

    // Fetch the single active test first
    const activeDoc = await fetchActiveTest();
    setActiveTest(activeDoc);

    // Then fetch the main list
    const testsData = await fetchPostTests(pageSize);

    // Combine them, making sure to remove the active doc from the main list if it appears
    let combinedList = testsData.list || [];
    if (activeDoc) {
      combinedList = combinedList.filter(item => item.id !== activeDoc.id);
      combinedList.unshift(activeDoc); // place at top
    }

    // Format each test item to include formatted numbers and the CTR column
    const formattedList = combinedList.map(item => formatTestItem(item));
    setTestsDB(formattedList);
    window.localStorage.setItem('testsDB', JSON.stringify(formattedList));
    setLoading(testsData.loaded);
    setFirstVisible(testsData.firstVisible);
    setLastVisible(testsData.lastVisible);
  };

  useEffect(() => {
    handleGetTests();
    // eslint-disable-next-line
  }, [pageSize]);

  // 3. Update state helper to keep the active test pinned
  const updateState = (documents) => {
    if (!documents.empty) {
      const tempPosts = [];
      documents.forEach((document) => {
        tempPosts.push({
          id: document.id,
          ...document.data(),
        });
      });

      // If there's an active test, ensure it's at index 0
      let newData = tempPosts;
      if (activeTest) {
        newData = newData.filter(item => item.id !== activeTest.id);
        // Place active test at the top
        newData.unshift(activeTest);
      }

      // Format the new data to add commas and CTR before updating state
      const formattedData = newData.map(item => formatTestItem(item));
      setTestsDB(formattedData);
    }

    // Update visible boundaries
    if (documents?.docs[0]) {
      setFirstVisible(documents.docs[0]);
    }
    if (documents?.docs[documents.docs.length - 1]) {
      setLastVisible(documents.docs[documents.docs.length - 1]);
    }
    setLoading(false);
  };

  // 4. Next page
  const nextPage = async () => {
    setLoading(true);

    const q = query(
      collection(db, "postTests"),
      orderBy("postTime", "desc"),
      startAfter(lastVisible),
      limit(pageSize)
    );

    const documents = await getDocs(q);
    updateState(documents);
  };

  // 5. Previous page
  const previousPage = async () => {
    setLoading(true);

    const q = query(
      collection(db, "postTests"),
      orderBy("postTime", "desc"),
      endBefore(firstVisible),
      limitToLast(pageSize)
    );

    const documents = await getDocs(q);
    updateState(documents);
  };

  return (
    <div className='posttests__container'>
      <h1 className='posttests__heading'>Post Tests</h1>
      <Search />

      <div>
        <button className='posttests__button' onClick={() => handleGetTests(null)}>
          Fetch new tests
        </button>
      </div>

      <div>
        <button
          className='posttests__button'
          onClick={() => (window.location.href = '/newposttest')}
        >
          CREATE new test
        </button>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <Table
          caption="Latest Post Tests"
          data={testsDB}
          columns={columns}
          path='test'
        />
      )}

      <div className='posttests__pagination'>
        <button
          className='posttests__button'
          style={{ width: 100 }}
          onClick={previousPage}
        >
          Previous
        </button>
        <button
          className='posttests__button'
          style={{ width: 100 }}
          onClick={nextPage}
        >
          Next
        </button>

        <select
          style={{ width: 'auto', marginLeft: 20, padding: 5 }}
          name="pageSize"
          id="pageSize"
          onChange={(e) => {
            setPageSize(parseInt(e.target.value, 10));
          }}
        >
          <option value="5">5</option>
          <option value="30">30</option>
          <option value="42">42</option>
          <option value="54">54</option>
        </select>
      </div>
    </div>
  );
};

export default PostTests;
